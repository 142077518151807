<template>
    <Dialog :header=dialogHeader v-model:visible="display" :modal="true" class='create-edit-group-portfolio-modal'
        :draggable='false' @show='onShown'>
        <template v-if="screen === 0">
            <div class='portfolio-name-input-container'>
                <div :class="{ 'p-float-label': true, 'input-error': v$.portfolioName.$error }">
                    <InputText id='portfolioNameInput' type="text" v-model='v$.portfolioName.$model'
                        @input="resetError('portfolioName')" />
                    <label for='portfolioNameInput' class='input-label'>Portfolio name</label>
                </div>
                <template v-for="error of v$.portfolioName.$errors" :key="error.$uid">
                    <span class='error-message'>{{ error.$message }}</span>
                </template>
            </div>
            <div v-if="editMode" class='portfolio-desc-textarea-container'>
                <span :class="{ 'p-float-label': true, 'input-error': v$.portfolioDescription.$error }">
                    <Textarea id='portfolioDescriptionTextarea' rows="5" cols="30" v-model='v$.portfolioDescription.$model' />
                    <label for='portfolioDescriptionTextarea' class='input-label'>Portfolio description</label>
                    <span class='portfolio-desc-counter'
                        :style="{ color: v$.portfolioDescription.$error ? '#E63E3E' : null }">{{ (portfolioDescription ?
                            portfolioDescription.length : 0) }} / {{ portfolioDescLimit }} </span>
                </span>
            </div>
            <div v-if="editMode" class="row">
                <div class="column">
                    <span>Lock portfolio:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Locking the Portfolio prevents any additional investment accounts from being added to the portfolio by group members.`"></i>
                    </div>
                </div>
                <div class="column">
                    <InputSwitch v-model="locked" class="create-portfolio-switch" />
                </div>
            </div>
            <div v-if="editMode && !isCollaborativePortfolio" class="row" >
                <div class="column">
                    <span>Show dollar amounts:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`If enabled then the dollar value of member accounts holdings are shown in group portfolio holdings table and the performance chart.`"></i>
                    </div>
                </div>
                <div class="column" v-if="editMode && hasPortfolioAccounts"
                    v-tooltip.bottom="`You can only change the setting when there are no accounts in the portfolio`">
                    <InputSwitch v-model="showDollarAmounts" class="create-portfolio-switch"
                        :disabled="editMode && hasPortfolioAccounts" />
                </div>
                <div class="column" v-else>
                    <InputSwitch v-model="showDollarAmounts" class="create-portfolio-switch" />
                </div>
            </div>
            <div v-if="editMode" class="row">
                <!-- <div class="column">
                    <span>Show portfolio in group performance section:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`If enabled then the portfolio will be shown in the performance section on the right side of the group profile for all members.`"></i>
                    </div>
                </div>
                <div class="column">
                    <InputSwitch v-model="showPerformanceOnGroupPreview" class="create-portfolio-switch" />
                </div> -->
            </div>
            <div v-if="editMode" class="row">
                <div class="column">
                    <span>Select default currency for portfolio holdings:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Any accounts in the portfolio not in this default currency will have their holdings converted before being displayed in the portfolio overview.`"></i>
                    </div>
                </div>
                <div class="column">
                    <SelectButton class="portfolio-select-button" :unselectable="false" v-model="defaultCurrencyId"
                        :options="currencyOptions" optionLabel="label" optionValue="value" />
                </div>
            </div>
            <template v-if="editMode && !isCollaborativePortfolio">
                <div class="row">
                    <span>Allowed account types (select at least one):</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Restricts the types of accounts that can be added to the aggregate portfolio.`"></i>
                    </div>
                </div>
                <div class="check-box-row">
                    <div>
                        <CheckBox inputId="paperAnalysis" :value="portfolioAccountTypes.paperAnalysis"
                            v-model="allowedPortfolioAccountTypes" />
                        <label for="paperAnalysis">Manual</label>
                    </div>
                    <div>
                        <CheckBox inputId="paperTrading" :value="portfolioAccountTypes.paperTrading"
                            v-model="allowedPortfolioAccountTypes" />
                        <label for="paperTrading">Paper trading</label>
                    </div>
                </div>
            </template>
        </template>
        
        <template v-if="screen === 1 && isCollaborativePortfolio">
            <h5 class="portfolio-header">Collaborative settings:</h5>
            <div class="row">
                <div class="column">
                    <span>Portfolio manager:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`The users able to manage this portfolio. Managers can execute trades (if Automatic Execution not enabled), Veto motions, update trading floor hours and suspend trading.`"></i>
                    </div>
                </div>
                <div class="column">
                    <Dropdown class="portfolio-dropdown" :options="portfolioManagerOptions" optionLabel="label"
                        v-model="portfolioManager" :disabled="true" />
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <span>Quorum:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`The minimum number of voters required for a valid vote to take place.  If a motion does not reach quorum before its deadline then the vote is not tallied and the motion is automatically withdrawn.`"></i>
                    </div>
                </div>
                <div class="column">
                    <div id='quorumInputContainer' :class="{ 'p-float-label': true, ' input-error': v$.quorum.$error }">
                        <InputNumber id='quorumInput' type="text" v-model='v$.quorum.$model' :suffix="quorum == 1 ? ' voter' : ' voters'"
                            @input="resetError('quorum')" :min="3"/>
                        <template v-for="error of v$.quorum.$errors" :key="error.$uid">
                            <span class='error-message'>{{ error.$message }}</span>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <span>Percentage to pass:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Minimum % of Yes votes for motion to be passed.`"></i>
                    </div>
                </div>
                <div class="column">
                    <div id='percentageToPassContainer' :class="{ 'p-float-label': true, ' input-error': v$.percentageToPass.$error }">
                        <InputNumber id='percentageToPassInput' type="text" v-model='v$.percentageToPass.$model' suffix=" %"
                            @input="resetError('percentageToPass')" :min="1" :max="100"/>
                        <template v-for="error of v$.percentageToPass.$errors" :key="error.$uid">
                            <span class='error-message'>{{ error.$message }}</span>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <span>Motion hours:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Motions can only be submitted by members during these hours.`"></i>
                    </div>
                </div>
                <div class="column">
                    <span class="hours-label">From:</span>
                    <Dropdown class="portfolio-dropdown" :options="tradingStartTimeOptions" optionLabel="label"
                        v-model="tradingStartTime" />
                    <span class="hours-label">To:</span>
                    <Dropdown class="portfolio-dropdown" :options="tradingStopTimeOptions" optionLabel="label"
                        v-model="tradingStopTime" />
                </div>
            </div>
            <div class="row" v-if="editMode">
                <div class="column">
                    <span>Suspend motions:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`Prevents motions from being submitted.`"></i>
                    </div>
                </div>
                <div class="column">
                    <InputSwitch v-model="suspendTradingFloor" class="create-portfolio-switch" />
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <span>Default motion deadline:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`How long members have to vote once a motion is submitted.  Once the deadline is reached no further votes are allowed and the motion votes are tallied. Max is 72 hours.`"></i>
                    </div>
                </div>
                <div class="column">
                    <div id='defaultMotionDeadlineInputContainer' :class="{ 'p-float-label': true, ' input-error': v$.defaultMotionDeadline.$error }">
                        <InputNumber id='defaultMotionDeadlineInput' type="text" v-model='v$.defaultMotionDeadline.$model' :suffix="defaultMotionDeadline == 1 ? ' hour' : ' hours'"
                            @input="resetError('defaultMotionDeadline')" :min="1" :max="72"/>
                        <template v-for="error of v$.defaultMotionDeadline.$errors" :key="error.$uid">
                            <span class='error-message'>{{ error.$message }}</span>
                        </template>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <span>Automatic execution:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`If enabled then when a motion passes the trade order is automatically submitted for execution.  If disabled then a portfolio manager must manually execute the trade order.`"></i>
                    </div>
                </div>
                <div class="column">
                    <InputSwitch v-model="automaticExecution" class="create-portfolio-switch" />
                </div>
            </div>
            <div class="row">
                <div class="column">
                    <span>Feed:</span>
                    <div class="info-icon-container">
                        <i class="pi pi-info-circle" 
                            v-tooltip.bottom="`By default submitted motions are displayed in the Latest group feed. If you have created other feeds you can choose to display them there instead.`"></i>
                    </div>
                </div>
                <div class="column">
                    <Dropdown class="portfolio-dropdown" :options="groupFeeds" optionLabel="name" v-model="groupFeed" />
                </div>
            </div>
            <div class='portfolio-textarea-container'>
                <div class="info-icon-container">
                    <i class="pi pi-info-circle" 
                        v-tooltip.bottom="`Any preferences or restrictions on the types trades you want to allow can be entered here. The text will be displayed to members when the create a motion.`"></i>
                </div>
                <span :class="{ 'p-float-label': true, 'input-error': v$.tradeCriteria.$error }">
                    <Textarea id='tradeCriteriaTextarea' rows="3" cols="30" v-model='v$.tradeCriteria.$model' />
                    <label for='tradeCriteriaTextarea' class='input-label'>Trade criteria (optional)</label>
                    <span class='portfolio-desc-counter'
                        :style="{ color: v$.tradeCriteria.$error ? '#E63E3E' : null }">{{ (tradeCriteria ?
                            tradeCriteria.length : 0) }} / {{ tradeCriteriaLimit }} </span>
                </span>
            </div>
            <div class='portfolio-textarea-container'>
                <div class="info-icon-container">
                    <i class="pi pi-info-circle" 
                        v-tooltip.bottom="`Any advice you want to give to members about how to write their rationale for making the trade. This text appears as a place holder in the editor for the Detailed Rationale when a member creates a motion.`"></i>
                </div>
                <span :class="{ 'p-float-label': true, 'input-error': v$.motionHints.$error }">
                    <Textarea id='motionHintsTextarea' rows="3" cols="30" v-model='v$.motionHints.$model' />
                    <label for='motionHintsTextarea' class='input-label'>Motion hints (optional)</label>
                    <span class='portfolio-desc-counter'
                        :style="{ color: v$.motionHints.$error ? '#E63E3E' : null }">{{ (motionHints ?
                            motionHints.length : 0) }} / {{ motionHintsLimit }} </span>
                </span>
            </div>
        </template>

        <template #footer>
            <Button v-if="screen === screensIds.collaborative" class='footer-portfolio-button' label="Back" @click='onFooterBackButtonClick' />
            <Button class='footer-portfolio-button' :label=footerForwardButtonLabel :disabled='disableFooterForwardButton'
                :loading='loading' @click='onFooterForwardButtonClick' />
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import CheckBox from 'primevue/checkbox';
import Textarea from 'primevue/textarea';
import SelectButton from 'primevue/selectbutton';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';

import GroupService from '../../../service/GroupService';

import useVuelidate from '@vuelidate/core';
import { maxLength, minValue, maxValue } from '@vuelidate/validators';
import EventBus from '../../../event-bus';
import { mapState } from 'vuex';

import { CANADIAN_CURRENCY_TYPE, COLLABORATIVE_PORTFOLIO_TYPE, US_CURRENCY_TYPE } from '../../../common/constants';

const dialogHeaderOptions = {
    aggregate: {
        create: "Create Aggregate Portfolio",
        edit: "Edit Aggregate Portfolio"
    },
    collaborative: {
        create: "Create Collaborative Portfolio",
        edit: "Edit Collaborative Portfolio"
    }
};

const footerForwardButtonLabelOptions = {
    create: "Create",
    edit: "Save",
    next: "Next"
};

const portfolioAccountTypes = {
    paperAnalysis: "paperAnalysis",
    paperTrading: "paperTrading"
};

const currencyOptions = [
    { label: CANADIAN_CURRENCY_TYPE.SYMBOL, value: CANADIAN_CURRENCY_TYPE.ID },
    { label: US_CURRENCY_TYPE.SYMBOL, value: US_CURRENCY_TYPE.ID }
];

const portfolioManagerOptions = [
    { label: 'Any Admin or Owner', value: 1 }
];

const screensIds = {
    general: 0,
    collaborative: 1
};

const motionHintsDefaultText = 
`Support your motion with compelling reasons and put your best reasons first. 
Include graphs, charts and other data if it will help you tell your story.
Write as clearly and succinctly as you can. Avoid overly long paragraphs.
Respond to questions and comments. This can help sway people.`;

export default {
    name: 'CreateEditGroupPortfolioModal',
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            screensIds,
            currencyOptions,
            portfolioManagerOptions,
            motionHintsDefaultText,

            display: false,
            loading: false,
            editMode: false,
            screen: 0,
            isCollaborativePortfolio: true,

            portfolio: null,
            groupFeeds: [],

            portfolioName: null,
            portfolioDescription: null,

            portfolioDescLimit: 999,
            tradeCriteriaLimit: 500,
            motionHintsLimit: 500,

            locked: true,
            showDollarAmounts: true,
            showPerformanceOnGroupPreview: false,
            defaultCurrencyId: US_CURRENCY_TYPE.ID,
            allowedPortfolioAccountTypes: [],

            portfolioAccountTypes: portfolioAccountTypes,

            hasPortfolioAccounts: true,

            tradingStartTimeOptions: null,
            tradingStopTimeOptions: null,

            portfolioManager: portfolioManagerOptions[0],
            quorum: null,
            percentageToPass: null,
            tradingStartTime: null,
            tradingStopTime: null,
            defaultMotionDeadline: 3,
            automaticExecution: true,
            groupFeed: null,
            suspendTradingFloor: false,
            tradeCriteria: null,
            motionHints: null,
        }
    },
    components: {
        Dialog,
        InputText,
        Textarea,
        CheckBox,
        InputSwitch,
        SelectButton,
        Dropdown,
        InputNumber,
    },

    computed: {
        ...mapState(['selectedGroup']),

        dialogHeader() {
            if(this.editMode){
                return this.isCollaborativePortfolio ? dialogHeaderOptions.collaborative.edit : dialogHeaderOptions.aggregate.edit;
            } else {
                return this.isCollaborativePortfolio ? dialogHeaderOptions.collaborative.create : dialogHeaderOptions.aggregate.create;
            }
        },

        disableFooterForwardButton() {
            return this.v$.$errors.length > 0 || !this.portfolioName ? 'disabled' : null;
        },

        footerForwardButtonLabel() {
            if (this.isCollaborativePortfolio && this.screen === screensIds.general) {
                return footerForwardButtonLabelOptions.next;
            } else if (this.editMode) {
                return footerForwardButtonLabelOptions.edit;
            } else {
                return footerForwardButtonLabelOptions.create;
            }
        }
    },

    created() {
        this.initTradingFloorHoursOptions();
    },

    methods: {
        resetError(field) {
            this.v$[field].$reset();
        },

        onShown() {
           
        },

        open(data) {
            this.v$.$reset();
            this.loading = false;
            this.display = true;
            this.screen = screensIds.general;

            this.portfolio = data?.portfolio;

            this.isCollaborativePortfolio = data?.isCollaborativePortfolio === true
                || this.portfolio?.groupPortfolioTypeId === COLLABORATIVE_PORTFOLIO_TYPE.ID;

            if (this.isCollaborativePortfolio) {
                this.fetchGroupFeeds();
            }

            if (this.portfolio) {
                this.editMode = true;

                this.initPortfolioInfo();
            } else {
                this.editMode = false;

                this.clearPortfolioInfo();
            }
        },

        clearPortfolioInfo() {
            this.portfolioName = '';
            this.portfolioDescription = '';

            this.locked = false;
            this.showDollarAmounts = false;
            this.showPerformanceOnGroupPreview = false;
            this.defaultCurrencyId = US_CURRENCY_TYPE.ID;

            this.allowedPortfolioAccountTypes = [];

            this.clearCollaborativePortfolioInfo();
        },

        clearCollaborativePortfolioInfo() {
            this.quorum = 10;
            this.percentageToPass = 30;
            this.tradingStartTime = this.tradingStartTimeOptions[0];
            this.tradingStopTime = this.tradingStopTimeOptions[6];
            this.defaultMotionDeadline = 3;
            this.automaticExecution = true;
            this.suspendTradingFloor = false;
            this.tradeCriteria = '';
            this.motionHints = motionHintsDefaultText;
        },

        initPortfolioInfo() {
            this.checkGroupPortfolioAccounts();

            this.showPerformanceOnGroupPreview = this.portfolio.groupPortfolioId == this.selectedGroup.defaultGroupPortfolioId;

            this.portfolioName = this.portfolio.name;
            this.portfolioDescription = this.portfolio.description;

            this.locked = this.portfolio.locked;
            this.showDollarAmounts = this.portfolio.showDollarAmounts;
            this.defaultCurrencyId = this.portfolio.defaultCurrencyId;

            this.allowedPortfolioAccountTypes = [];
            if (this.portfolio.allowPaperTradingAccounts) {
                this.allowedPortfolioAccountTypes.push(portfolioAccountTypes.paperTrading);
            }
            if (this.portfolio.allowPaperAnalysisAccounts) {
                this.allowedPortfolioAccountTypes.push(portfolioAccountTypes.paperAnalysis);
            }

            if (this.isCollaborativePortfolio) {
                this.initCollaborativePortfolioInfo();
            }
        },

        initCollaborativePortfolioInfo() {
            this.quorum = this.portfolio.collaborationSettings.quorum;
            this.percentageToPass = this.portfolio.collaborationSettings.percentageToPass;
            this.tradingStartTime = this.tradingStartTimeOptions.find(tradingStartTime => 
                tradingStartTime.value.hour == this.portfolio.collaborationSettings.tradingStartTime.hour);
            this.tradingStopTime = this.tradingStopTimeOptions.find(tradingStopTime => 
                tradingStopTime.value.hour == this.portfolio.collaborationSettings.tradingStopTime.hour);
            this.defaultMotionDeadline = this.portfolio.collaborationSettings.defaultDeadline;
            this.automaticExecution = this.portfolio.collaborationSettings.automaticExec;
            this.suspendTradingFloor = this.portfolio.collaborationSettings.suspendTradingFloor;
            this.tradeCriteria = this.portfolio.collaborationSettings.tradeCriteria;
            this.motionHints = this.portfolio.collaborationSettings.motionHints;
        },

        checkGroupPortfolioAccounts() {
            this.hasPortfolioAccounts = true;

            GroupService.getGroupPortfolioAccounts(this.selectedGroup.groupId, this.portfolio.groupPortfolioId).then(resp => {
                if (resp.data.status === 'success') {
                    const portfolioAccounts = resp.data.portfolioAccounts;
                    this.hasPortfolioAccounts = portfolioAccounts && portfolioAccounts.length > 0;
                }
            });
        },

        fetchGroupFeeds() {
            GroupService.getGroupFeeds(this.selectedGroup.groupId).then(resp => {

                if (resp.data.status == "success") {

                    this.groupFeeds = [];

                    let latest = {
                        groupId: this.selectedGroup.groupId,
                        groupFeedId: 0,
                        name: 'Latest'
                    };

                    if (resp?.data?.groupFeeds?.length > 0 && this.selectedGroup.groupId == resp.data.groupFeeds[0].groupId) {
                        this.groupFeeds = resp.data.groupFeeds;
                    }

                    this.groupFeeds.push(latest);
                    
                    if(this.portfolio?.collaborationSettings?.groupFeedId){
                        this.groupFeed = this.groupFeeds.find(groupFeed => groupFeed.groupFeedId == this.portfolio.collaborationSettings.groupFeedId);
                    } else {
                        this.groupFeed = latest;
                    }

                } else {
                    console.error("error getting group feeds :" + resp.data.message)
                }

            }).catch(err => {
                console.log("error getting group feeds: ", err);
            });
        },

        initTradingFloorHoursOptions() {
            let tradingFloorHoursOptions = [];
            for (let i = 6; i <= 23; i++) {
                const localTime = { hour: i, minute: 0, second: 0, nano: 0 };
                if (i < 12) {
                    tradingFloorHoursOptions.push({ 'label': i + ':00 am', 'value': localTime });
                } else if (i == 12) {
                    tradingFloorHoursOptions.push({ 'label': i + ':00 pm', 'value': localTime });
                } else {
                    tradingFloorHoursOptions.push({ 'label': (i - 12) + ':00 pm', 'value': localTime });
                }
            }
            this.tradingStartTimeOptions = tradingFloorHoursOptions;
            this.tradingStartTime = this.tradingStartTimeOptions[0];
            this.tradingStopTimeOptions = tradingFloorHoursOptions;
            this.tradingStopTime = this.tradingStopTimeOptions[6];
        },

        isInputDataValid() {
            if(this.screen === screensIds.general){
                this.v$.portfolioName.$touch();
                if(this.editMode){
                    this.v$.portfolioDescription.$touch();
                }

                if (this.v$.$errors.length > 0) {
                    console.log("isInputDataValid", this.v$.$errors);
                    return false;
                } else if (!this.portfolioName) {
                    this.$toast.add({ severity: 'warn', summary: "Please enter a name", life: 4500, group: 'center' });
                    return false;
                } else if (this.editMode && this.portfolioDescription && this.portfolioDescription.length > this.portfolioDescLimit) {
                    this.$toast.add({ severity: 'warn', summary: "Please reduce the size of the description", life: 4500, group: 'center' });
                    return false;
                } else if (!this.isCollaborativePortfolio && this.editMode && (!this.allowedPortfolioAccountTypes || this.allowedPortfolioAccountTypes.length == 0)) {
                    this.$toast.add({ severity: 'warn', summary: "Please select one or more portfolio type", life: 4500, group: 'center' });
                    return false;
                }

            } else if(this.screen === screensIds.collaborative && this.isCollaborativePortfolio){
                this.v$.quorum.$touch();
                this.v$.percentageToPass.$touch();
                this.v$.tradeCriteria.$touch();
                this.v$.motionHints.$touch();
                this.v$.defaultMotionDeadline.$touch();

                if (this.v$.$errors.length > 0) {
                    console.log("isInputDataValid", this.v$.$errors);
                    return false;
                } else if (!this.quorum) {
                    this.$toast.add({ severity: 'warn', summary: "Please enter a quorum", life: 4500, group: 'center' });
                    return false;
                } else if (this.tradingStartTime?.value?.hour >= this.tradingStopTime?.value?.hour) {
                    this.$toast.add({ severity: 'warn', summary: "Please make sure trading floor start time is earlier than stop time.", life: 4500, group: 'center' });
                    return false;
                } else if (!this.percentageToPass) {
                    this.$toast.add({ severity: 'warn', summary: "Please enter a percentage to pass", life: 4500, group: 'center' });
                    return false;
                } else if (!this.defaultMotionDeadline) {
                    this.$toast.add({ severity: 'warn', summary: "Please enter a default motion deadline", life: 4500, group: 'center' });
                    return false;
                }
            }

            return true;
        },

        isPortfolioTypeChosen(portfolioType) {
            if (this.isCollaborativePortfolio || !this.editMode) {
                return portfolioType == portfolioAccountTypes.paperTrading;
            }
            return this.allowedPortfolioAccountTypes.includes(portfolioType);
        },

        onFooterBackButtonClick() {
            this.screen = screensIds.general;
        },

        onFooterForwardButtonClick() {
            if (this.isInputDataValid()) {
                if(this.screen === screensIds.general && this.isCollaborativePortfolio){
                    this.screen = screensIds.collaborative;
                    return;
                }

                this.loading = true;

                const collaborationSettings = this.isCollaborativePortfolio ? {
                    quorum: this.quorum,
                    percentageToPass: Number(this.percentageToPass).toFixed(1),
                    tradingStartTime: this.tradingStartTime.value,
                    tradingStopTime: this.tradingStopTime.value,
                    defaultDeadline: this.defaultMotionDeadline,
                    automaticExec: this.automaticExecution,
                    groupFeedId: this.groupFeed.groupFeedId == 0 ? null : this.groupFeed.groupFeedId,
                    suspendTradingFloor: this.suspendTradingFloor,
                    tradeCriteria: this.tradeCriteria,
                    motionHints: this.motionHints,
                } : undefined;

                console.log("collaborationSettings", collaborationSettings);

                if (this.editMode) {
                    GroupService.updateGroupPortfolio(this.portfolio.groupId, this.portfolio.groupPortfolioId, this.portfolioName, this.portfolioDescription,
                        this.locked, this.showDollarAmounts, true, this.defaultCurrencyId, this.isPortfolioTypeChosen(portfolioAccountTypes.paperTrading),
                        this.isPortfolioTypeChosen(portfolioAccountTypes.paperAnalysis), false,
                        collaborationSettings).then((resp) => {

                            if (resp.data.status === 'success') {
                                EventBus.emit('update-group-portfolio-holdings-currency', {
                                    groupPortfolioId: this.portfolio.groupPortfolioId,
                                    defaultCurrencyId: this.defaultCurrencyId
                                });
                                // this.$toast.add({ severity: 'success', summary: 'Updated Portfolio!', life: 2500, group: 'center' });
                                this.setShowPerformanceOnGroupPreview(resp.data.portfolio.groupPortfolioId, "Updated Portfolio!");
                            }
                            else if (resp.data.message == 'Invalid Name') {
                                this.$toast.add({ severity: 'error', summary: "Invalid portfolio name.", life: 3500, group: 'center' });
                            }
                            else {
                                console.error(resp.data.message);
                                this.$toast.add({ severity: 'error', summary: "An unexpected error occurred.  Please try again or click on 'Support' under your profile pic.", life: 3500, group: 'center' });
                            }
                        });
                } else {
                    GroupService.createGroupPortfolio(this.selectedGroup.groupId, this.portfolioName, this.portfolioDescription,
                        this.locked, this.showDollarAmounts, true, this.defaultCurrencyId, this.isPortfolioTypeChosen(portfolioAccountTypes.paperTrading),
                        this.isPortfolioTypeChosen(portfolioAccountTypes.paperAnalysis), false,
                        collaborationSettings).then((resp) => {

                            if (resp.data.status === 'success') {
                                // this.$toast.add({ severity: 'success', summary: 'Created Portfolio: successful!', life: 2500, group: 'center' });
                                this.setShowPerformanceOnGroupPreview(resp.data.portfolio.groupPortfolioId, "Created Portfolio: successful!");

                                EventBus.emit('track-creating-group-portfolio');
                            }
                            else if (resp.data.message == 'Portfolios limit is full') {
                                this.$toast.add({ severity: 'error', summary: "Portfolios limit is full.", life: 3500, group: 'center' });
                            }
                            else if (resp.data.message == 'Invalid Name') {
                                this.$toast.add({ severity: 'error', summary: "Invalid portfolio name.", life: 3500, group: 'center' });
                            } else {
                                console.error(resp.data.message);
                                this.$toast.add({ severity: 'error', summary: "An unexpected error occurred.  Please try again or click on 'Support' under your profile pic.", life: 3500, group: 'center' });
                            }
                        });
                }
            }
        },

        setShowPerformanceOnGroupPreview(groupPortfolioId, msg) {
            GroupService.updateGroupSettings(this.selectedGroup.groupId, this.showPerformanceOnGroupPreview ? groupPortfolioId : null)
                .then(resp => {
                    this.loading = false;
                    this.display = false;

                    if (resp.data.status == "success" && resp.data.group) {

                        this.$store.commit('SET_SELECTED_GROUP', resp.data.group);
                        EventBus.emit('refresh-group-portfolios');

                        this.$toast.add({ severity: 'success', summary: msg, life: 2500, group: 'center' });
                    } else {
                        this.$toast.add({ severity: 'error', summary: `Unable to set portfolio as a default due to an unexpected error.`, life: 2500 });
                    }
                });
        },
    },

    validations() {
        return {
            portfolioName: {
                maxLength: maxLength(50)
            },

            portfolioDescription: {
                maxLength: maxLength(this.portfolioDescLimit)
            },

            quorum: {
                minValue: minValue(3)
            },

            percentageToPass: {
                minValue: minValue(1),
                maxValue: maxValue(100)
            },

            defaultMotionDeadline: {
                minValue: minValue(1),
                maxValue: maxValue(72)
            },

            tradeCriteria: {
                maxLength: maxLength(this.tradeCriteriaLimit)
            },

            motionHints: {
                maxLength: maxLength(this.motionHintsLimit)
            },
        }
    }
}
</script>

<style>
.create-edit-group-portfolio-modal {
    position: static;
    font-family: "Trebuchet MS", "Verdana";
    width: 565px;
    max-height: 98% !important;
    border-radius: 16px;
}

.create-edit-group-portfolio-modal .p-dialog-header {
    padding: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.create-edit-group-portfolio-modal .p-dialog-header .p-dialog-title {
    font-size: 24px;
    font-weight: bold;
}

.create-edit-group-portfolio-modal .p-dialog-content {
    height: 100%;
    overflow-y: hidden;
    padding: 0 16px;
}

.create-edit-group-portfolio-modal .p-dialog-footer {
    text-align: center;
    padding: 16px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
</style>
<style scoped>
.field-radiobutton {
    margin-bottom: 24px;
    padding: 0px 80px;
    display: flex;
    justify-content: space-around;
}

.field-radiobutton .p-radiobutton {
    margin-right: 4px;
}

/* Shaking animation */
@keyframes shakeError {
    0% {
        transform: translateX(0);
    }

    15% {
        transform: translateX(0.375rem);
    }

    30% {
        transform: translateX(-0.375rem);
    }

    45% {
        transform: translateX(0.375rem);
    }

    60% {
        transform: translateX(-0.375rem);
    }

    75% {
        transform: translateX(0.375rem);
    }

    90% {
        transform: translateX(-0.375rem);
    }

    100% {
        transform: translateX(0);
    }
}

#quorumInputContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.p-inputnumber,
.p-inputnumber :first-child,
.p-inputnumber .p-inputtext {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
}

.p-dropdown {
    min-width: 110px;
}

.portfolio-name-input-container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}

.portfolio-name-input-container .p-inputtext {
    font-size: 16px;
    width: 100%;
    padding: 8px 8px 8px 16px;
    height: 36px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.portfolio-name-input-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}

.portfolio-name-input-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}

.portfolio-name-input-container>label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.portfolio-desc-textarea-container {
    margin-top: 24px;
}

.portfolio-desc-textarea-container .p-inputtextarea {
    font-size: 16px;
    resize: none;
    width: 100%;
    padding: 8px 8px 8px 16px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.portfolio-desc-textarea-container .p-inputtextarea::-webkit-scrollbar {
    /* width */
    width: 8px;
}

.portfolio-desc-textarea-container .p-inputtextarea::-webkit-scrollbar-thumb {
    background-color: #BFBFBF;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 9px solid #BFBFBF;
    /* creates padding around scroll thumb */
}

.portfolio-desc-textarea-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}

.portfolio-desc-textarea-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}

.portfolio-desc-textarea-container>label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.portfolio-desc-textarea-container .portfolio-desc-counter {
    font-size: 12px;
}

.portfolio-textarea-container .p-inputtextarea {
    resize: none;
    width: 100%;
    padding: 8px 8px 8px 16px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.portfolio-textarea-container .p-inputtextarea::-webkit-scrollbar {
    /* width */
    width: 8px;
}

.portfolio-textarea-container .p-inputtextarea::-webkit-scrollbar-thumb {
    background-color: #BFBFBF;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
    border: 9px solid #BFBFBF;
    /* creates padding around scroll thumb */
}

.portfolio-textarea-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}

.portfolio-textarea-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}

.portfolio-textarea-container>label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}

.portfolio-textarea-container .portfolio-desc-counter {
    font-size: 12px;
}

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}

.input-error .p-inputtext:hover,
.input-error .p-inputtext:enabled:focus,
.input-error .p-inputtext {
    border-color: #E63E3E;
}

.input-error .input-label {
    color: #E63E3E;
}

.error-message:nth-child(2) {
    padding-top: 10px;
}

.error-message {
    color: #E63E3E;
    padding-left: 10px;
}

.footer-portfolio-button {
    padding: 8px 32px;
    background: #33CC99;
    border: 1px solid #33CC99;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
}

.footer-portfolio-button:hover {
    background: #00C083;
    border-color: #00C083;
}

.footer-portfolio-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}


.group-actions-switch-container {
    display: grid;
    width: 200px;
    margin: 0px 16px 16px;
}

.p-inputswitch {
    margin-left: auto;
    display: flex;
}

.row {
    display: flex;
    align-items: center;
    padding: 4px 0px;
    min-height: 32px;
}

h5 {
    margin: 8px 0;
}

.row :last-child.column {
    justify-content: flex-end;
}

.check-box-row {
    display: flex;
    align-items: center;
    padding: 4px 0px;
}
.check-box-row > div {
    margin-right: 32px;
}
.check-box-row > div:last-child {
    margin-right: 0;
}

.p-checkbox {
    margin-right: 4px !important;
}

.column {
    flex-grow: 1;
    display: flex;
    align-items: center;
}

.portfolio-select-button {
    margin-left: auto;
    width: fit-content;
}

.portfolio-textarea-container .info-icon-container i {
    margin-left: auto;
}

.info-icon-container {
    padding: 0 2px 5px;
    display: flex;
}

.info-icon-container i {
    color: #00C083;
}

::v-deep(.portfolio-select-button .p-button) {
    font-size: 0.9rem !important;
    padding: 4px 6px 4px 6px;
}

::v-deep(.portfolio-select-button .p-button:focus) {
    box-shadow: none;
}

.hours-label {
    margin: 0px 4px;
}
</style>